<script lang="ts" setup>
defineOptions({
  inheritAttrs: false,
});

const [modelValue] = defineModel({ type: String, required: true, default: '' });

defineProps<{
  errors?: string;
}>();
</script>

<template>
  <slot name="label" />
  <div>
    <input
      type="email"
      v-bind="$attrs"
      autocomplete="email"
      v-model="modelValue"
      :class="['email', { 'email--error': errors, 'email--success': !errors && modelValue }]"
    />
  </div>
  <tw-form-error v-if="errors">
    {{ errors }}
  </tw-form-error>
</template>

<style lang="scss" scoped>
@import '~/assets/styles/tw-variables.scss';
@import '~/assets/styles/tw-form.scss';

div {
  position: relative;
}

.email {
  padding-right: 3rem;
  @include tw-input-string;
}
</style>
